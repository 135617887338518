
import { defineComponent, computed } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useGettext } from "vue3-gettext";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "callback",
  created() {
    const router = useRouter();
    const store = useStore();
    const gettext = useGettext();

    const token = this.$route.query.token;
    console.log(token);
    if (token == null) {
      console.log("REDIRECT TO SIGN-IN");
      router.push({ name: "sign-in" });
    }
    setTimeout(() => {
      store
        .dispatch(Actions.CHECK, { token })
        .then(() => {
          console.log("REDIRECT TO DASHBOARD");
          router.push({ name: "dashboard" });
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: gettext.$gettext("Try again!"),
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          }).then(() => {
            console.log("REDIRECT TO SIGN-IN");
            router.push({ name: "sign-in" });
          });
        });
    }, 100);
  },
  setup() {
    const gettext = useGettext();
    const store = useStore();

    const countries = {
      en: {
        flag: "media/flags/united-states.svg",
        name: "English",
        value: "en",
      },
      sl: {
        flag: "media/flags/slovenia.svg",
        name: "Slovenščina",
        value: "sl",
      },
    };

    const setLang = (lang) => {
      localStorage.setItem("lang", lang);
      gettext.current = lang;
      store.dispatch(Actions.LANGUAGE_CHANGED);
    };

    const currentLanguage = (lang) => {
      return gettext.current === lang;
    };

    const currentLangugeLocale = computed(() => {
      return countries[gettext.current];
    });

    return {
      setLang,
      currentLanguage,
      currentLangugeLocale,
      countries,
    };
  },
});
