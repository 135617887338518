<template>
  <div
    class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
    style="background-image: url('media/illustrations/progress-hd.png')"
  >
    <!--begin::Content-->
    <div class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
      <!--begin::Logo-->
      <a href="https://printeepro.com" class="mb-12">
        <img
          alt="Logo"
          src="media/logos/printeepro_logo_dark.png"
          class="h-45px"
        />
      </a>
      <!--end::Logo-->

      <!--begin::Wrapper-->
      <div class="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
        <h1 class="anchor fw-bolder mb-5">
          Loading
          <span
            class="spinner-border spinner-border-sm align-middle ms-2"
          ></span>
        </h1>
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Content-->

    <!--begin::Footer-->
    <div class="d-flex flex-center flex-column-auto p-10">
      <!--begin::Links-->
      <div class="d-flex align-items-center fw-bold fs-6">
        <a
          href="https://printeepro.com"
          class="text-muted text-hover-primary px-2"
          >{{ $gettext("About") }}</a
        >

        <a
          href="mailto:support@printeepro.com"
          class="text-muted text-hover-primary px-2"
          >{{ $gettext("Contact Us") }}</a
        >

        <a
          href="https://printeepro.com/privacy"
          class="text-muted text-hover-primary px-2"
          >{{ $gettext("Privacy Policy") }}</a
        >
      </div>
      <!--end::Links-->
    </div>
    <!--end::Footer-->
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useGettext } from "vue3-gettext";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "callback",
  created() {
    const router = useRouter();
    const store = useStore();
    const gettext = useGettext();

    const token = this.$route.query.token;
    console.log(token);
    if (token == null) {
      console.log("REDIRECT TO SIGN-IN");
      router.push({ name: "sign-in" });
    }
    setTimeout(() => {
      store
        .dispatch(Actions.CHECK, { token })
        .then(() => {
          console.log("REDIRECT TO DASHBOARD");
          router.push({ name: "dashboard" });
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: gettext.$gettext("Try again!"),
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          }).then(() => {
            console.log("REDIRECT TO SIGN-IN");
            router.push({ name: "sign-in" });
          });
        });
    }, 100);
  },
  setup() {
    const gettext = useGettext();
    const store = useStore();

    const countries = {
      en: {
        flag: "media/flags/united-states.svg",
        name: "English",
        value: "en",
      },
      sl: {
        flag: "media/flags/slovenia.svg",
        name: "Slovenščina",
        value: "sl",
      },
    };

    const setLang = (lang) => {
      localStorage.setItem("lang", lang);
      gettext.current = lang;
      store.dispatch(Actions.LANGUAGE_CHANGED);
    };

    const currentLanguage = (lang) => {
      return gettext.current === lang;
    };

    const currentLangugeLocale = computed(() => {
      return countries[gettext.current];
    });

    return {
      setLang,
      currentLanguage,
      currentLangugeLocale,
      countries,
    };
  },
});
</script>
